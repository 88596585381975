.detection {
  position: absolute;
  border: 1px solid white;
  z-index: 1;
}

.detection--selection {
  border-style: dashed;
}

.detection-grid {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .detection-grid--h::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: white;
    opacity: 0.25;
    top: 33%;
  }
  
  .detection-grid--h::before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: white;
    opacity: 0.25;
    top: 66%;
  }
  
  .detection-grid--v::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: white;
    opacity: 0.25;
    left: 33%;
  }
  
  .detection-grid--v::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: white;
    opacity: 0.25;
    left: 66%;
  }