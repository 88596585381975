@import '~antd/dist/antd.dark.css';
:root {
  --background: #f8f7f6;
  --grey: #f6f5f2;
  --blue: #60a0b0;
  --green: #00c4b4;
  --purple: #555387;
  --gold: #ffb819;
  --orange: #e88c4d;
  --red: #ff7800;
  --sidebar-width: 370px;
  --confidence-blue: #39e6d4;
  --confidence-orange: #f5ab18;
  --confidence-red: #cc3813;
}

body,
#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gds-account-modal {
  background: var(--background);
}

.gds-account-modal--pearl-logo:before {
  content: '';
  display: block;
  width: 238px;
  height: 177px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 1.5rem;
  /* background-image: url('./images/logo_black.svg'); */
}

/* Recolor */
.gds-button--primary,
.gds-button--dark-toggle:hover,
.gds-button--dark-toggle.gds-button--active,
.gds-button--toggle.gds-button--active,
.gds-multi-select__button--primary,
.gds-onboarder__button,
.gds-anim-timeline__button--remove,
.react-datepicker__today-button,
.ReactTable .-pagination .-btn,
.DayPicker-TodayButton {
  background-color: var(--orange);
  box-shadow: none;
}

.gds-button--primary:hover,
.gds-button--dark-toggle:hover,
.gds-button--toggle.gds-button--active:hover,
.gds-multi-select__button--primary:hover,
.gds-onboarder__button:hover,
.gds-anim-timeline__button--remove:hover,
.react-datepicker__today-button:hover,
.ReactTable .-pagination .-btn:hover,
.DayPicker-TodayButton:hover {
  background-color: var(--red);
}

.gds-form-group__text-input {
  border: 1px solid var(--blue);
  box-shadow: none;
}

.gds-form-group__text-input:hover,
.gds-form-group__text-input:focus,
.gds-form-group__text-input:hover:focus {
  border: 1px solid var(--blue);
  box-shadow: none;
}

.gds-form-group__text-input:focus,
.gds-form-group__text-input:hover:focus,
.gds-form-group__text-input:hover {
  box-shadow: inset 0 -0.1875rem 0 0 var(--blue), inset 0 0 0 1px var(--blue),
    0 0 3px 0 rgba(0, 0, 0, 0.2);
}

/* Dropzone */

.dropzone-wrapper {
  width: 100%;
  height: 100%;
  max-width: 50%;
  max-height: 50%;
  padding: 2rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-radius: 2px;
  border-color: #444;
  border-style: dashed;
  background: rgba(100, 100, 100, 0.2);
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.dropzone:focus,
.dropzone:hover {
  border-color: #2196f3;
  box-shadow: inset 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
}

.dropzone.disabled {
  opacity: 0.6;
}

.gds-lens--display {
  transition: opacity 500ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.annotation-list {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 49px;
  right: 0;
  bottom: 0;
  width: var(--sidebar-width);
  z-index: 1000;
  overflow-y: auto;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.3);
}

/* Header */

.gds-spa-header {
  background: var(--background);
}

/* Lens hover */
.gds-lens.gds-lens--display:hover {
  z-index: 1000;
}

/* Make colored Lens outlines */

.gds-lens__viewport--blue:after {
  box-shadow: inset 0 0 0 1px var(--confidence-blue);
}

.gds-lens__viewport--gold:after {
  box-shadow: inset 0 0 0 1px var(--gold);
}

.gds-lens__viewport--orange:after {
  box-shadow: inset 0 0 0 1px var(--confidence-orange);
}

.gds-lens__viewport--purple:after {
  box-shadow: inset 0 0 0 1px var(--purple);
}

.gds-lens__viewport--red:after {
  box-shadow: inset 0 0 0 1px var(--confidence-red);
}

.-color-tx-red {
  color: var(--confidence-red) !important;
}

.-color-tx-orange {
  color: var(--confidence-orange) !important;
}

.-color-tx-blue {
  color: var(--confidence-blue) !important;
}

.gds-lens.gds-lens--display:hover .gds-lens__viewport:after {
  box-shadow: inset 0 0 0 1px white;
}

/* Confidence slider */
.gds-form-group__range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.25rem;
  cursor: pointer;
}

.gds-form-group__range-input::-webkit-slider-thumb {
  height: 1.25rem;
}

.centered--full-width {
  right: 0;
  flex-direction: column;
}

.annotation-list--scrollable {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* Tree hover effect */
.gds-tree__link-blue-hover {
  color: var(--confidence-blue) !important;
}

.gds-tree__link-green-hover {
  color: var(--green) !important;
}

.gds-tree__link-purple-hover {
  color: var(--purple) !important;
}

.gds-tree__link-gold-hover {
  color: var(--gold) !important;
}

.gds-tree__link-orange-hover {
  color: var(--confidence-orange) !important;
}

.gds-tree__link-red-hover {
  color: var(--confidence-red) !important;
}

.gds-tree__link-hover .gds-tree__status-icon {
  left: 5px;
  color: var(--blue);
}

.gds-tree__link-hover .gds-tree__status-icon:before {
  content: '\f0d9';
}

.gds-tree__link:hover .gds-tree__status-icon {
  width: 14px;
}

/* Lens hover effect */
.gds-lens--display-hover {
  z-index: 1000;
}

.gds-lens--display:after,
.gds-lens--display:before {
  content: none;
}

.gds-lens--display-hover .gds-lens__bracket,
.gds-lens--display-hover.gds-lens--active .gds-lens__bracket {
  opacity: 1;
  transform: translate(0, 0);
}

.gds-lens--display-hover ~ .gds-lens-base {
  filter: grayscale(100%);
}

.gds-lens--display-hover .gds-lens__viewport {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.85);
}
.gds-lens-wrapper {
  position: relative;
  text-align: center;
}

.flex-lens-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.loading-holder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.7);
}

.lens-blur-fade {
  max-width: 100%;
  max-height: 100%;
  animation-name: fade-in;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.copyright {
  position: absolute;
  bottom: 1rem;
  left: 1.5rem;
}

.pearl-header .gds-spa-header__section {
  border: none;
}

.pearl-header .gds-spa-header__section-icon {
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: 70%;
  /* background-image: url('./images/favicon.png'); */
  background-position: center center;
}

.pearl-header .gds-spa-header__section-logo {
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-image: url('./images/logo_black.svg'); */
  background-position: center;
  border: none;
}

.pearl-header .app-name {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding-top: 0.9rem;
  padding-left: 0.5rem;
}

.pearl-header .avatar-header-section {
  border-left: 1px solid #ccc;
  background-color: #fff;
  color: black;
  text-align: center;
}

.pearl-header
  .gds-spa-header__secondary-nav
  .gds-spa-header__section
  .gds-avatar__image {
  border: 2px solid #eee;
  background-color: #fff;
  font-weight: bold;
}

.gds-bubble__menu-list-link:hover,
.gds-avatar__menu-list-link:hover {
  background-color: var(--orange);
}

.ant-layout-footer {
  padding: 12px 50px;
  display: flex;
  padding: 12px 50px;
  display: flex;
  justify-content: space-between;
  background-color: #1f1f1f;
}

.ant-layout-sider-trigger {
  height: 64px;
}

.ant-badge {
  position: absolute;
}

.ant-popover-title,
.ant-popover-inner-content {
  text-align: center;
}
.ant-popover-title {
  padding-top: 11px;
  padding-bottom: 11px;
}
.ant-popover-title span {
  font-weight: bold;
}
.ant-popover-inner-content a {
  color: #ffffff;
}
.ant-popover-inner-content a:hover {
  color: #a9a5a5;
}

.site-sidebar {
  width: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
.site-sidebar button {
  width: 165px;
  margin-bottom: 10px;
}
.site-sidebar .toggle-all-icon {
  margin-right: 5px;
}
.site-sidebar .ant-collapse-content-box {
  display: flex;
  flex-direction: column;
}
