.site-top {
  height: 100%;
  width: 100%;
  display: flex;
}

.site-layout-background {
  padding: 24px;
  background: #262626;
  height: 100%;
  width: calc(100% - 200px);
}

.image-layer {
  max-height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
}
